import React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import YoutubePlayerLite from "../common/YoutubePlayerLite"

const BlogVideosListSectionNew = () => {
  const data = useStaticQuery(graphql`
    query BlogVideosListSectionNewQuery {
      allPrismicYoutubeVideo(
        limit: 4
        sort: {fields: data___date_published, order: DESC}
      ) {
        edges {
          node {
            data {
              youtube_video_title
              youtube_video_id
              date_published
            }
          }
        }
      }
    }
  `)

  const youtubeVideos = data.allPrismicYoutubeVideo.edges

  return (
    <div className="bg-gray-900 w-full px-4 pt-16 pb-4 md:pt-24 md:pb-16">
      <div className="w-full max-w-screen-xl mx-auto">
        <div className="mb-12">
          <h2 className="text-white text-3xl text-center md:text-4xl">Top Site Themes Videos</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 xl:gap-12">
          {youtubeVideos.map(item => {
            return (
              <div className="w-full mb-12 md:mb-0">
                <YoutubePlayerLite youtubeID={item.node.data.youtube_video_id} />

                <h2 className="text-white font-medium text-3xl md:text-4xl mt-6">{item.node.data.youtube_video_title}</h2>
              </div>
            )
          })}  
        </div>

        <div className="flex items-center justify-center mt-0 md:mt-12 mb-12">
          <Link to="/blog/category/video/" className="text-white text-lg md:text-xl text-center hover:underline">View All Videos</Link>
        </div>
      </div>
    </div>
  )
}

export default BlogVideosListSectionNew