import * as React from "react"

import Layout from "../../components/layout/layout"
import BlogHeroSection from "../../components/sections/blog-hero-section"
import Seo from "../../components/seo"
import BlogVideosListSectionNew from "../../components/sections/blog-videos-list-section-new"
import BlogPostsListSection from "../../components/sections/blog-posts-list-section"

const BlogPage = () => {
  return (
    <Layout>
      <Seo 
        title="Blog"
        description="We share stories and solutions for the modern developer and entrepreneur. Learn how to create a website with the best templates, themes, plugins and apps you'll find here."
      />

      <BlogHeroSection />

      <BlogPostsListSection />

      <BlogVideosListSectionNew />
    </Layout>
  )
}

export default BlogPage
